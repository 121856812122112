import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";

import styled from "styled-components";
import socketIOClient from "socket.io-client";
import JSONPretty from "react-json-pretty";

import { useRouter } from "next/router";
import { ActionButton } from "./components/ActionButton";
import { StatsItem } from "./components/StatsItem";
import queryString from "query-string";
import { v4 } from "uuid";
//@ts-ignore
import customParser from "socket.io-msgpack-parser";
export interface ServerState {
  uuid: string;
  workerState?: string;
  workerConfig?: string;
  state?: string;
  workerStatus?: string;
  workerError?: string;
}
const getString = () => {
  return strings[Math.round(Math.random() * (strings.length - 1))];
};
const strings = [
  "End of passion play",
  "Crumbling away",
  "I'm your source of self-destruction",
  "Veins that pump with fear",
  "Sucking darkeness clear",
  "Leading on your death's construction",
  "Taste me, you will see",
  "More is all you need",
  "Dedicated to",
  "How I'm killing you",
  "Come crawling faster (faster)",
  "Obey your master (master)",
  "Your life burns faster (faster)",
  "Obey your",
  "Master",
  "Master",
  "Master of puppets, I'm pulling your strings",
  "Twisting your mind and smashing your dreams",
  "Blinded by me, you can't see a thing",
  "Just call my name 'cause I'll hear you scream",
  "Master",
  "Master",
  "Just call my name 'cause I'll hear you scream",
  "Master",
  "Master",
  "Needlework the way",
  "Never you betray",
  "Life of death becoming clearer",
  "Pain monopoly",
  "Ritual misery",
  "Chop your breakfast on a mirror",
  "Taste me you will see",
  "More is all you need",
  "Dedicated to",
  "How I'm killing you",
  "Come crawling faster (faster)",
  "Obey your master (master)",
  "Your life burns faster (faster)",
  "Obey your Master",
  "Master",
  "Master of puppets, I'm pulling your strings",
  "Twisting your mind and smashing your dreams",
  "Blinded by me, you can't see a thing",
  "Just call my name 'cause I'll hear you scream",
  "Master",
  "Master",
  "Just call my name 'cause I'll hear you scream",
  "Master",
  "Master",
  "(Master, master)",
  "Master",
  "Master",
  "Where's the dreams that I've been after?",
  "Master",
  "Master",
  "Promised only lies",
  "Laughter",
  "Laughter",
  "All I hear or see is laughter",
  "Laughter",
  "Laughter",
  "Laughing at my cries",
  "Fix me",
  "Hell is worth all that",
  "Natural habitat",
  "Just a rhyme without a reason",
  "Never-ending maze",
  "Drift on numbered days",
  "Now your life is out of season",
  "I will occupy",
  "I will help you die",
  "I will run through you",
  "Now I rule you too",
  "Come crawling faster (faster)",
  "Obey your master (master)",
  "Your life burns faster (faster)",
  "Obey your master",
  "Master",
  "Master of puppets, I'm pulling your strings",
  "Twisting your mind and smashing your dreams",
  "Blinded by me, you can't see a thing",
  "Just call my name 'cause I'll hear you scream",
];
const userNames = [
  "Donny_Chestnuts",
  "Barca12821",
  "RocknSausageRoll",
  "Brighton_Boy",
  "AndieDream95 ",
  "Terrance",
  "IHeartIndie",
  "FreddyDibs100",
  "MariaIsHere ",
  "Ranjit_Jenkins",
  "PrinceFan",
  "SuperStella ",
  "10Olivia10",
  "SusieSakè",
  "LovingLondonLife",
  "Kevin",
  "YungHumphrey ",
  "Jonas2022",
  "NikkiEdwards",
  "NocturnalGirl ",
  "SunriseSunset ",
  "Gian-pierre",
  "GeorgeOhWell",
  "StockholmSounds",
  "Andre",
  "Isabella",
  "BrazilGirl",
  "GuitarNdrums",
  "Santiago",
  "AtHomeinTokyo",
  "BrokenBeats",
  "YanaCherzana",
  "Addi",
  "PingPong",
  "KellyandSam ",
  "Punksintrunks",
  "Larryminglong",
  "TheLoudCrowd",
  "BillyBo ",
  "Mary",
  "Sergiosentme",
  "QueensEnglish",
  "BeachLife",
  "Hieroglyphic",
  "Tamar ",
  "Pnut",
  "DrRobotNick",
  "JasmineJackson",
  "Anissa",
  "GeniDXD",
  "43degrees ",
  "DevKumar",
  "Wanasuka",
  "JankoDS5",
  "TheBoss ",
  "MorenoTheSurfer",
  "BikesandBooks",
  "LaurenBeukes ",
  "Mikhail",
  "Himansh",
  "FabiolaSanchez",
  "OsmanIsTheMan",
  "BassDude",
  "JagJago",
  "Byron",
  "DeeanneAnderson",
  "SunNsnow",
  "SoundWave",
  "RangersFan",
  "HiFromPrague",
  "IndieKiss_xoxo",
  "Makingmusic22",
  "Oksana",
  "YoSatasha",
  "Jack",
  "PontusUAE",
  "Mahmoud",
  "19SEL84",
  "PrakashDEL",
  "Dizzy ",
  "Jacek50",
  "Mirna",
  "MrGuo",
  "Bukayo1",
  "Chhaya",
  "SitiFromTheCity",
  "Angelina ",
  "Evelina ",
  "SOLfan",
  "MegaMegan",
];
export default function App(this: any) {
  // const { query } = useRouter();
  const query: any = useMemo(() => {
    return queryString.parse(window.location.search);
  }, []);
  console.log(query);
  const chatInput = useRef<HTMLInputElement | null>(null);
  // console.log(query);
  // const query = queryString.parse(window.location.search);
  const socketPath = query.socketpath
    ? query.socketpath.toString()
    : "ws://127.0.0.1:8080";
  const socketUrl = `${socketPath}`;
  const [chatId, setChatId] = useState(v4());
  const [lastMessage, setLastMessage] = useState("");
  const [readyState, setReadyState] = useState("");
  const stickyObjectId = "testStickyID";
  const timer = async (time: number) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  };
  const register = () => {
    console.log("send register");
    sendMessage({
      type: "UserRegister",
      sessionId: userId,
      visible: query.visible ? Boolean(query.visble) : undefined,
      // subscribeTo: [chatChannel],
    });
  };
  const registerRef = useRef(register);

  const subsrcibeToChannel = () => {
    sendMessage({
      type: "UserUpdate",
      name: userNames[Math.round(Math.random() * userNames.length - 1)].trim(),
      // name: "A",
    });
    setTimeout(() => {
      sendMessage({
        type: "ChannelSubscribe",
        channel: query.channel,
        users: true,
        stickyObjects: true,
        history: true,
      });
    }, 500);
  };
  const subsrcibeToChannelRef = useRef(subsrcibeToChannel);
  // var p1 = [0.15078158770752959, -0.04031912713314756];
  // var p2 = [0.22404846762041752, 0.21665993456653343];
  // var p1 = [0.25, 0.25];
  // var p2 = [-0.25, -0.25];
  // var a = p1[0] - p2[0];
  // var b = p1[1] - p2[1];
  // var max = 0.7071067811865476;
  // var c = Math.sqrt(a * a + b * b);
  // console.log(c);
  const typeMessage = async () => {
    const str = getString();
    if (query.vibetype === "true") {
      await timer(100);

      sendMessage({
        type: "UserAction",
        action: "StartTyping",
      });
      await timer(100);
      for (let i = 0; i < str.length; i++) {
        sendMessage({
          messageId: chatId,
          type: "Text",
          text: str.substring(0, i + 1),
          keep: false,
          // answerTarget: "Printer",
          channel: query.channel,
        });
        await timer(100 + Math.round(Math.random() * 200));
        // await timer(10);
      }
    }
    sendChat(true, str);
  };
  // useEffect(() => {
  //   if (window !== undefined) {
  //     console.log("start interval");
  //     setInterval(() => {
  //       sendPing();
  //     }, 5000);
  //   }
  // }, []);

  const sendPing = () => {
    console.log("sendPing");
    sendMessage({
      type: "Ping",
    });
  };
  // const [userId, setUserId] = useState("session" + Math.random());
  const userId = v4();
  // const { sendMessage, readyState, lastMessage } = useSocketIO(socketUrl, {
  //   onOpen: async () => {
  //     console.log("onOpen")
  //     sendMessage(
  //       JSON.stringify({
  //         type: "UserRegister",
  //         sessionId: userId,
  //         visible: query.visible ? Boolean(query.visble) : undefined,
  //         // subscribeTo: [chatChannel],
  //       })
  //     );
  //   },
  //   onError: (event) => {
  //     console.log("error", event);
  //   },
  //   onClose: (event) => {
  //     console.log("onClose", event);
  //   },
  //   //Will attempt to reconnect on all close events, such as server shutting down
  //   shouldReconnect: () => true,
  // });
  const [messages, setMessages] = useState<string[]>([]);
  const messageRef = useRef(messages);
  const [IOSocket, setIOSocket] = useState<any>();
  const sendMessage = (message: any) => {
    // console.log(message);
    // const msg = {
    //   type: "Batch",
    //   messages: [message],
    // };
    //@ts-ignore
    window.myActiveSocket?.send(message);
  };
  const storeMessage = (data: any) => {
    // debugger
    // console.log("store it", data)
    // setLastMessage(data);

    // console.log("messageRef", messageRef.current);
    // setMessages([data, ...messages]);
    setMessages([data, ...messageRef.current]);
  };
  const storeMessageRef = useRef(storeMessage);
  useEffect(() => {
    //@ts-ignore
    if (query.token && !window.myActiveSocket) {
      console.log("initialize socket", query.token);
      const auth: any = { token: `${query.token}` };
      if (query.group) {
        auth.group = query.group;
      }
      if (query.force) {
        auth.forceNew = true;
      }
      // auth.role = "moderator";
      //@ts-ignore
      window.myActiveSocket?.close();

      const socket = socketIOClient(socketPath, {
        auth,
        withCredentials: true,
        transports: ["websocket"],
        parser: customParser,
      });
      //@ts-ignore
      window.myActiveSocket = socket;
      setIOSocket(socket);
      socket.on("connect_error", (error) => {
        if (error.message === "TOO_MANY_SESSIONS") {
          console.log("DO YOUR THING HERE 8");
        }
      });
      socket.on("connect", () => {
        console.log("connected");
        setTimeout(() => {
          // console.log("Run register");
          registerRef.current();
        }, 200);
      });
      socket.on("message", (data) => {
        console.log("received", data);
        if (data.type === "InitialState") {
          console.log("inistal state is here");
          subsrcibeToChannelRef.current();
        }
        storeMessageRef.current(data);
      });
      socket.on("event_data", (data, callback) => {
        console.log("EVENT DATA UPDATE", data);
        storeMessage(data);
        console.log(socket.id);
        if (callback) {
          console.log("callback exists", callback);
          callback(socket.id);
        }
      });

      socket.on("close", () => {
        // window.reload();
      });
      socket.on("disconnect", (reason) => {
        console.log("disconnect", reason);
        if (reason === "io server disconnect") {
        }
      });
      socket.on("error", (err) => {
        console.log(err);
      });
    }
  }, [query.token, storeMessage]);

  // const [messageLog,setMessageLog] = useState<string[]>([]);
  // useEffect(() => {
  //   console.log("setMessageLog")
  //   setMessageLog(messages.current)
  // },[messages.current])

  const getChannels = async () => {
    console.log("This is the call to get channels");
    let result = await fetch(
      "https://8f288511dc0e.ngrok.io/_workers/cog/channels?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiZTA4MjI4MzgtN2VmYS00YjYyLTlmZTItZjJkMGJmN2Q0MzI4IiwidXNlclJvbGUiOiJhdHRlbmRlZSIsInVzZXJJZCI6IjFiYTYzM2E3LWRjY2EtNDA1YS04NmY4LTQyZGI0ZWQzMTk0ZiIsImlhdCI6MTY0OTg1OTY2OSwic3ViIjoiYmpvcm5Aa3VtbWVuZWplLnNlIn0.EzMq9k50G8WgneI-_7IDZT6NQ4n2u28ehl-21X-2MEc&group=xyz"
    );
    console.log(result);
  };

  // useEffect(() => {
  //   // console.log("lastMessage", lastMessage);
  //   let msgs = lastMessage?.data;
  //   if (lastMessage) {
  //     setMessages([msgs, ...messages]);
  //   }
  // }, [lastMessage]);
  const settings = [
    // {
    //   testId: "btn-register",
    //   id: "Register",
    //   message: {
    //     name: query.name + " {{RANDOM}}",
    //     // userId: query.userId,
    //     type: "Register",
    //     sessionId: "session{{RANDOM}}",
    //   },
    // },

    {
      testId: "btn-position",
      id: "Position",
      message: {
        type: "UserUpdate",
        position: ["{{RANDOMPOSITION}}", "{{RANDOMPOSITION2}}"],
      },
    },
    {
      testId: "btn-clap",
      id: "Clap",
      message: {
        type: "Reaction",
        channel: query.channel,
        object: "Clap",
      },
    },
    {
      testId: "btn-reaction",
      id: "Reaction",
      message: {
        type: "Reaction",
        object: { type: "he", count: Math.round(Math.random() * 6) },
        channel: "reactions",
      },
    },
    {
      testId: "btn-blockuser",
      id: "Block user",
      message: {
        type: "UserLevel",
        userId: "61be4b5c-4809-4058-b99b-b37836ccd5a5",
        level: "ghosted",
      },
    },
    {
      testId: "btn-unblockuser",
      id: "Unblock user",
      message: {
        type: "UserLevel",
        userId: "5dc9b72e-18c9-4e4a-ad47-caf1fc7f53c8",
        level: "full",
      },
    },
    {
      testId: "btn-blockmessage",
      id: "Block message",
      message: {
        type: "TextUpdate",
        blocked: true,
        messageId: query.messageid,
        channel: query.channel,
      },
    },
    {
      testId: "btn-unblockmessage",
      id: "Unblock message",
      message: {
        type: "TextUpdate",
        blocked: false,
        messageId: query.messageid,
        channel: query.channel,
      },
    },

    {
      testId: "btn-getusers",
      id: "GetUsers",
      message: {
        type: "GetUsers",

        channel: "global",
      },
    },
    {
      testId: "btn-pinmessage",
      id: "Pin message",
      message: {
        type: "TextUpdate",
        pinned: true,
        messageId: query.messageid,
        channel: query.channel,
      },
    },
    {
      testId: "btn-unpinmessage",
      id: "Unpin message",
      message: {
        type: "TextUpdate",
        pinned: false,
        messageId: query.messageid,
        channel: query.channel,
      },
    },
    // {
    //   testId: "btn-chatmessage",
    //   id: "Send chat message",
    //   message: {
    //     messageId: Math.random(),
    //     type: "ChatMessage",
    //     text: "{{INPUT}}",
    //     keep: true,
    //   },
    // },
    {
      testId: "btn-setname",
      id: "Set new name",
      message: {
        type: "UserUpdate",
        name: "A new name {{RANDOM}}",
      },
    },
    {
      testId: "btn-starttyping",
      id: "Start typing",
      message: {
        type: "UserAction",
        action: "StartTyping",
        channel: query.channel,
      },
    },
    {
      testId: "btn-endtyping",
      id: "End typing",
      message: {
        type: "UserAction",
        action: "EndTyping",
        channel: query.channel,
      },
    },
    {
      testId: "btn-subscribe",
      id: "Subscribe to query channel",
      message: {
        type: "ChannelSubscribe",
        channel: query.channel,
        users: true,
        stickyObjects: true,
        history: true,
      },
    },
    {
      testId: "btn-unsubscribe",
      id: "Unsubscribe to query channel",
      message: {
        type: "ChannelUnsubscribe",
        channel: query.channel,
      },
    },
    {
      testId: "btn-createsticky",
      id: "Create Sticky Object",
      message: {
        type: "StickyObjectCreate",
        active: false,
        objectId: stickyObjectId,
        channel: query.channel,
        object: {
          content: "test",
        },
      },
    },
    {
      testId: "btn-updatesticky",
      id: "Update Sticky Object",
      message: {
        type: "StickyObjectUpdate",
        active: true,
        objectId: stickyObjectId,
        channel: query.channel,
      },
    },
    {
      testId: "btn-removesticky",
      id: "Remove Sticky Object",

      message: {
        type: "StickyObjectRemove",
        objectId: stickyObjectId,
        channel: query.channel,
      },
    },
  ];

  const sendData = (obj: any) => {
    let str = JSON.stringify(obj);
    str = str.split("{{RANDOM}}").join(Math.random().toString());
    str = str
      .split("{{RANDOMPOSITION}}")
      .join((1500 - Math.random() * 3000).toString());
    str = str
      .split("{{RANDOMPOSITION2}}")
      .join((1500 - Math.random() * 3000).toString());
    str = str.split("{{INPUT}}").join(chatInput.current?.value);
    // console.log(str);
    sendMessage(JSON.parse(str));
  };
  const sendChat = async (keep: boolean = false, str: string = "") => {
    sendMessage({
      // answerTarget: keep ? "Printer" : "Printer",
      messageId: chatId,
      type: "Text",
      text: str,
      keep: keep,
      channel: query.channel,
    });

    if (keep) {
      setChatId(v4());
    }
  };
  const sendPurge = async () => {
    sendMessage({
      type: "Purge",
    });
  };
  const [position, setPosition] = useState([0, 0]);
  const [direction, setDirection] = useState([1, 0]);
  const [stepLength, setStepLength] = useState(10000);
  const setInitialPosition = () => {
    setPosition([0.35 - Math.random() * 0.7, 0.2 - Math.random() * 0.4]);
  };
  const setCenterPosition = () => {
    setPosition([
      (0.5 - Math.random() * 1) * 500,
      (0.5 - Math.random() * 1) * 500,
    ]);
  };
  const takeDanceStep = () => {
    let x = position[0] + (direction[0] / 100) * stepLength;
    let y = position[1] + (direction[1] / 100) * stepLength;
    setPosition([x, y]);
  };
  useEffect(() => {
    if (sendMessage) {
      // console.log(position);
      sendMessage({
        type: "UserUpdate",
        position: position,
        channel: query.channel,
      });
    }
    setDirection([1 - Math.random() * 2, 1 - Math.random() * 2]);
    setStepLength(5000 * Math.random());
  }, [position, query.channel]);
  return (
    <Container>
      <div>
        <div>DIO test v0.5</div>
        <input
          ref={chatInput}
          data-testid="input-chat-message"
          onChange={() => sendChat()}
        />
        {settings.map((item) => {
          return (
            <ActionButton
              key={item.id}
              testId={item.testId}
              onClick={() => sendData(item.message)}
              label={item.id}
            />
          );
        })}
        <ActionButton
          testId="btn-register"
          onClick={() => register()}
          label="Register"
          key="chatmsg"
        />
        <ActionButton
          testId="btn-centerposition"
          onClick={() => setCenterPosition()}
          label="Set center position"
          key="centerpos"
        />
        <ActionButton
          testId="btn-initialposition"
          onClick={() => setInitialPosition()}
          label="Set initial position"
          key="initialpos"
        />
        <ActionButton
          testId="btn-dance"
          onClick={() => takeDanceStep()}
          label="Take Dance Step"
          key="dance"
        />
        <ActionButton
          testId="btn-chatmessage"
          onClick={() => typeMessage()}
          label="Send Chat Message"
          key="register"
        />
        <ActionButton
          testId="btn-keepalive"
          onClick={() => console.log("keep alive")}
          label="Keep alive"
          key="keepalive"
        />
        <ActionButton
          testId="btn-purge"
          onClick={() => sendPurge()}
          label="Purge"
          key="purge"
        />
        <ActionButton
          testId="getChannels"
          onClick={() => getChannels()}
          label="Get Channels"
          key="getchannels"
        />
        {/* <ActionButton
          testId="btn-livetyping"
          onClick={() => typeMessage()}
          label="Livetype"
          key="chatmsg-live"
        /> */}
        {/* <ActionButton
          testId="btn-register"
          onClick={handleClickRegister}
          label="Register"
        />

        <ActionButton
          testId="btn-position"
          onClick={handleClickPosition}
          disabled={readyState !== ReadyState.OPEN}
          label="Position"
        />
       
        <ActionButton
          testId="btn-message"
          onClick={handleClickSendMessage}
          disabled={readyState !== ReadyState.OPEN}
          label="Send Chat message"
        />

        <ActionButton
          testId="btn-setname"
          onClick={handleSetName}
          disabled={readyState !== ReadyState.OPEN}
          label="Set new name"
        />

        <ActionButton
          testId="btn-starttyping"
          onClick={handleStartTyping}
          disabled={readyState !== ReadyState.OPEN}
          label="Start typing"
        />

        <ActionButton
          testId="btn-endtyping"
          onClick={handleEndTyping}
          disabled={readyState !== ReadyState.OPEN}
          label="End Typing"
        />

        <ActionButton
          testId="btn-createsticky"
          onClick={handleCreateStickyObject}
          disabled={readyState !== ReadyState.OPEN}
          label="Create sticky object"
        />

        <ActionButton
          testId="btn-updatesticky"
          onClick={handleUpdateStickyObject}
          disabled={readyState !== ReadyState.OPEN}
          label="Update Sticky object"
        />
        <ActionButton
          testId="btn-removesticky"
          onClick={handleRemoveStickyObject}
          disabled={readyState !== ReadyState.OPEN}
          label="Remove Sticky object"
        />
        <ActionButton
          testId="btn-gethistory-chat"
          onClick={handleGetHistory}
          disabled={readyState !== ReadyState.OPEN}
          label="Get Chat History"
        /> */}
      </div>

      <StatsItem label="The WebSocket is currently" value={""} />
      <StatsHolder>
        {messages.length}
        {query.trace &&
          query.trace === "true" &&
          messages.map((item, i) => {
            // return <Message>{item}</Message>;
            if (i > 10) {
              return;
            } else {
              return (
                <JSONPretty
                  key={`msg_${i}`}
                  id="json-pretty"
                  data={item}
                ></JSONPretty>
              );
            }
          })}
      </StatsHolder>
    </Container>
  );
}
// const Message = styled.code`
//   width: 100%;
//   font-size: 12px;
//   display: block;
// `;
// const SettingsHolder = styled.div`
//   text-align: left;
//   font-size: 10px;
// `;
// const Settings = styled.input`
//   width: 400px;
//   display: flex;
//   flex-direction: column;
// `;

const StatsHolder = styled.div`
  width: 100%;
  text-align: left;
  font-sise: 14px;
`;
const Container = styled.div`
  width: 100vw;
`;
