// import { Button } from "@/components/atom/form/Button";

interface Props {
  onClick: () => void;
  disabled?: boolean;
  label: string;
  testId?: string;
}
export const ActionButton = (props: Props) => {
  return (
    <button onClick={props.onClick} data-testid={props.testId}>
      {props.label}
    </button>
  );
};
