import styled from "styled-components";
interface Props {
  label: string;
  value: string | number | [];
}
export const StatsItem = (props: Props) => {
  return (
    <Item>
      {props.label}: {props.value}
    </Item>
  );
};
const Item = styled.div`
  width: 100vw;
`;
